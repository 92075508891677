import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineDashboard,
  MdPeopleAlt,
  MdCancel,
  MdOutlinePauseCircle,
  MdOutlinePersonOff,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { Row, Col, Card, Nav } from 'react-bootstrap';
// import { Table } from 'react-bootstrap';
import ColumnsTable from 'views/admin/dataTables/components/ColumnsTable';
import ComplexTable from 'views/admin/dataTables/components/ComplexTable';
import tableDataColumns from 'views/admin/dataTables/variables/tableDataColumns.json';
import tableDataComplex from 'views/admin/dataTables/variables/tableDataComplex.json';
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from 'views/admin/dataTables/variables/columnsData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Skeleton,
  Dropdown,
  Menu,
  Button,
  Table,
  Space,
  Typography,
  Tag,
  Empty,
  Tooltip,
  Spin,
  Modal,
  Tabs,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
  FrownOutlined,
  LoadingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobShareMail from 'components/Modals/JobShareMail';
const Jobs = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [jobs, setJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [inactiveJobs, setInactiveJobs] = useState([]);
  const [holdJobs, setHoldJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isJobModalVisible, setIsJobModalVisible] = useState(false);
  const [jobId, setJobId] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [statusLoader, setStatusLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [statusCounts, setStatusCounts] = useState({
    Active: 0,
    InActive: 0,
    Hold: 0,
    Closed: 0,
  });
  const token = localStorage.getItem('Token');

  const showModal = (jobId) => {
    setJobToDelete(jobId);
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    try {
      if (token) {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}/${jobToDelete}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        // console.log(data);
        getJobs();
        setJobToDelete(null);
        setIsModalVisible(false);
      } else {
        navigate('/login');
        setJobToDelete(null);
      }
    } catch (error) {
      // console.log(error);
      setJobToDelete(null);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const deleteJob = async (id) => {
    try {
      if (token) {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}/${id}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        // console.log(data);
        getJobs();
      } else {
        navigate('/login');
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const statusUpadte = async (status, id) => {
    try {
      console.log(id, status);
      // setStatusLoader(true);
      if (token) {
        let payload = {
          jobId: id,
          status: status,
        };
        // console.log();

        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/job/status/update`,
          payload,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(data);
        setStatusLoader(false);

        getJobs();
      } else {
        navigate('/login');
        setStatusLoader(false);
      }
    } catch (error) {
      console.log(error);
      setStatusLoader(false);
    }
  };
  const getJobs = async () => {
    try {
      //REACT_APP_GET_JOBS
      setLoader(true);
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        // console.log(data.jobs);
        setJobs(data?.jobs);
        const counts = data?.jobs.reduce((acc, job) => {
          acc[job.status] = (acc[job.status] || 0) + 1;
          return acc;
        }, {});
        setStatusCounts({
          Active: counts.Active || 0,
          InActive: counts.InActive || 0,
          Hold: counts.Hold || 0,
          Closed: counts.Closed || 0,
        });
        setLoader(false);
      } else {
        navigate('/login');
        setLoader(false);
      }
    } catch (error) {
      // console.log(error);
      setLoader(false);
    }
  };
  const items = [
    { label: 'Active', key: '0' },
    { label: 'InActive', key: '1' },
    { label: 'Closed', key: '2' },
    { label: 'Hold', key: '3' },
  ];
  const handleDropdownVisibleChange = (visible, jobId) => {
    setDropdownVisible((prev) => ({ ...prev, [jobId]: visible }));
  };
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Space direction="vertical">
          <Typography.Title level={5}>{text}</Typography.Title>
        </Space>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) => (
        <Space direction="vertical">
          <Typography.Text level={5}>{text}</Typography.Text>
        </Space>
      ),
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <Space direction="vertical">
          <Typography.Text level={5}>{text}</Typography.Text>
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Tag
          color={
            text === 'Active'
              ? 'green'
              : text === 'InActive'
              ? 'volcano'
              : text === 'Closed'
              ? 'red'
              : 'gold'
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space direction="vertical">
          <Space size="middle">
            <Tooltip title="Share" placement="top">
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu mode="vertical">
                    <Menu.Item
                      key="mail"
                      icon={<ShareAltOutlined />}
                      onClick={() => {
                        console.log('Share button clicked with id:');
                        jobModalShow(record._id);
                      }}
                    >
                      Mail
                    </Menu.Item>
                    <Menu.Item
                      key="copy"
                      icon={<MdFileCopy />}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_BASE_URL}/job/${record._id}`,
                        );
                        console.log('Link Copied');
                        toast.success('Link Copied', { autoClose: 2000 });
                      }}
                    >
                      Copy
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button icon={<ShareAltOutlined />}>Share</Button>
              </Dropdown>
            </Tooltip>
            <Tooltip title="Update Status">
              <Dropdown
                overlay={
                  <Menu>
                    {items.map((item) => (
                      <Menu.Item
                        key={item.key}
                        onClick={() => statusUpadte(item.label, record._id)}
                      >
                        {item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
                // visible={dropdownVisible[record.jobId]} // Control visibility based on state
                // onVisibleChange={(visible) =>
                //   handleDropdownVisibleChange(visible, record.jobId)
                // } // Update visibility state
                loading={statusLoader}
              >
                <Button>
                  Status <DownOutlined />{' '}
                </Button>
              </Dropdown>
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                icon={<EditOutlined />}
                onClick={() =>
                  navigate('/admin/postJobs', {
                    state: { jobId: record._id },
                  })
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => showModal(record._id)}
              />
            </Tooltip>
          </Space>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    getJobs();
  }, []);
  const jobModalShow = (id) => {
    console.log(id);
    setIsJobModalVisible(true);
    setJobId(id);
  };
  // const filteredJobs = jobs.filter((job) => job.status.toLowerCase() === activeTab.toLowerCase());
  useEffect(() => {
    const filterJobs = () => {
      const active = jobs.filter((job) => job.status === 'Active');
      const inactive = jobs.filter((job) => job.status === 'InActive');
      const hold = jobs.filter((job) => job.status === 'Hold');
      const closed = jobs.filter((job) => job.status === 'Closed');

      setActiveJobs(active);
      setInactiveJobs(inactive);
      setHoldJobs(hold);
      setClosedJobs(closed);
    };

    filterJobs();
  }, [jobs]);
  // const renderJobsTable = (jobList) => {
  //   return (
  //     <Card.Body>
  //       {jobList.length > 0 ? (
  //         <ColumnsTable
  //           columnsData={columnsDataColumns}
  //           deleteJob={deleteJob}
  //           statusUpadte={statusUpadte}
  //           tableData={jobList}
  //         />
  //       ) : (
  //         <p>No jobs available for the selected status.</p>
  //       )}
  //     </Card.Body>
  //   );
  // };
  console.log(isJobModalVisible);

  const renderJobsTable = (jobList) => {
    // if (jobList.length === 0) {
    //   return <Empty description="No Jobs Available" />;
    // }

    return (
      <Card.Body>
        <Table
          columns={columns}
          className="manage-tables"
          dataSource={jobList}
          rowKey={(record) => record.jobId}
          pagination={false}
          loading={loader}
          locale={{
            emptyText: <Empty description="No Jobs Available" />,
          }}
        />
      </Card.Body>
    );
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Jobs</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Explore our candidate database effortlessly. Narrow down your
                search using filters that matter to you - skills, experience,
                and more. When you find the perfect match, send an interview
                invitation and kickstart the hiring process!
              </span>
            </Card.Header>
            <div
              className="report-box"
              // style={{
              //   display: 'flex',
              //   justifyContent: 'space-around',
              //   alignItems: 'center',
              //   width: '100%',
              //   padding: '20px',
              //   backgroundColor: '#f0f0f0',
              //   borderRadius: '8px',
              //   marginBottom: '20px',
              //   marginTop: '25px',
              // }}
            >
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineDashboard}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Active"
                  // growth="+23%"
                  value={statusCounts.Active}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePersonOff}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Inactive"
                  // growth="+23%"
                  value={statusCounts.InActive}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePauseCircle}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Hold"
                  value={statusCounts.Hold}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdCancel}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="closed"
                  value={statusCounts.Closed}
                />
              </SimpleGrid>
            </div>
            <Card.Header>
              {/*    <Nav
                variant="tabs"
                className="candidate-tabs"
                activeKey={activeTab}
                onSelect={(selectedTab) => setActiveTab(selectedTab)}
              >
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="active" style={{ color: 'green' }}>
                    Active
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="inActive" style={{ color: 'blue' }}>
                    InActive
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
                    Hold
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
                    Closed
                  </Nav.Link>
                </Nav.Item>
              </Nav>*/}
              <br />
              {/*  <Card.Title as="h5">
                <code style={{ color: 'red' }}>
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
                  Invitations
                </code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>*/}

              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Active" key="1">
                  {renderJobsTable(activeJobs)}
                </Tabs.TabPane>
                <Tabs.TabPane tab="InActive" key="2">
                  {renderJobsTable(inactiveJobs)}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Hold" key="3">
                  {renderJobsTable(holdJobs)}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Closed" key="4">
                  {renderJobsTable(closedJobs)}
                </Tabs.TabPane>
              </Tabs>
            </Card.Header>

            {/* <ComplexTable
                columnsData={columnsDataComplex}
                tableData={tableDataComplex}
              /> 
            </Card.Body>*/}
            <Modal
              title="Delete Job"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>Do you want to delete this job?</p>
            </Modal>
            {isJobModalVisible && (
              <JobShareMail
                jobId={jobId}
                isJobModalVisible={isJobModalVisible}
                setIsJobModalVisible={setIsJobModalVisible}
              />
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Jobs;
{
  /*              
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: 'orange',
                  }}
                >
                  Hold
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                 
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: 'red',
                  }}
                >
                  Reject
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                  :2
                </div>
              </div>

              
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: 'green',
                  }}
                >
                  Accept
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                 :3
                </div>
              </div> */
}
{
  /*     <Card.Body>
           <NestedTable tab={activeTab} /> */
}
{
  /* <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </Table> */
}

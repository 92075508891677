import React, { useEffect, useState } from 'react';

// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card.js';
// import {
//   // barChartDataDailyTraffic,
//   // barChartOptionsDailyTraffic,
// } from 'variables/charts';
import { useNavigate } from 'react-router-dom';
// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import axios from 'axios';

export default function DailyTraffic(props) {
  const { ...rest } = props;
  const navigate = useNavigate();
  const token = localStorage.getItem('Token');
  const [jobCounts, setJobCounts] = useState();
  const [barChartDataDailyTraffic, setbarChartDataDailyTraffic] = useState([
    {
      name: 'JOBS',
      data: [],
    },
  ]);
  const [monthsWithJobs, setMonthsWithJobs] = useState({});
  const [categories, setCategories] = useState([]);
  // Utility to get formatted month strings
  const getFormattedMonth = (date) =>
    `${date.toLocaleString('default', { month: 'short' })}-${String(
      date.getFullYear(),
    ).slice(-2)}`;

  // Get previous three months
  const getPreviousThreeMonths = () => {
    const currentDate = new Date();
    const result = {};
    for (let i = 1; i <= 3; i++) {
      const prevDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1,
      );
      const formattedMonth = getFormattedMonth(prevDate);
      result[formattedMonth] = 0;
    }
    return result;
  };

  const getJobs = async () => {
    try {
      //REACT_APP_GET_JOBS
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(data);
        const counts = Array(12).fill(0);
        const tempMonthsWithJobs = {};

        data?.jobs.forEach((job) => {
          const createdAt = new Date(job.createdAt);
          const month = createdAt.getMonth();
          const year = createdAt.getFullYear();

          counts[month] += 1;

          const formattedMonth = getFormattedMonth(createdAt);

          tempMonthsWithJobs[formattedMonth] =
            (tempMonthsWithJobs[formattedMonth] || 0) + 1;
        });

        // Ensure at least three months are displayed
        const totalMonthsWithJobs = Object.keys(tempMonthsWithJobs).length;
        if (totalMonthsWithJobs < 3) {
          const previousMonths = getPreviousThreeMonths();
          Object.assign(previousMonths, tempMonthsWithJobs);
          setMonthsWithJobs(previousMonths);
          setbarChartDataDailyTraffic([
            {
              name: 'JOBS',
              data: Object.values(previousMonths),
            },
          ]);
        } else {
          setMonthsWithJobs(tempMonthsWithJobs);
          setbarChartDataDailyTraffic([
            {
              name: 'JOBS',
              data: Object.values(tempMonthsWithJobs),
            },
          ]);
        }

        setJobCounts(data?.jobs?.length);
      } else {
        navigate('/login');
      }
    } catch (error) {
      // console.log(error);
    }
  };
  console.log(jobCounts);

  useEffect(() => {
    getJobs();
  }, []);
  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
          fontFamily: undefined,
        },
      },
      theme: 'dark',
    },
    xaxis: {
      categories: Object.keys(monthsWithJobs),
      show: false,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: '#CBD5E0',
          fontSize: '14px',
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: '#4318FF',
              opacity: 1,
            },
            {
              offset: 100,
              color: 'rgba(67, 24, 255, 1)',
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '40px',
      },
    },
  };

  // const barChartDataDailyTraffic = [
  //   {
  //     name: 'JOBS',
  //     data: jobCounts,
  //   },
  // ];

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Total Posted Jobs
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              color={textColor}
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {jobCounts}
            </Text>
            <Text
              ms="6px"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Jobs
            </Text>
          </Flex>
        </Flex>
        {/* <Flex align='center'>
          <Icon as={RiArrowUpSFill} color='green.500' />
        <Text color='green.500' fontSize='sm' fontWeight='700'>
            +2.45%
          </Text>
        </Flex>*/}
      </Flex>
      <Box h="240px" mt="auto">
        <BarChart
          chartData={barChartDataDailyTraffic}
          chartOptions={barChartOptionsDailyTraffic}
        />
      </Box>
    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table, Badge, Dropdown, Menu } from 'antd';
import { Button } from '@chakra-ui/react';

import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdSend,
  MdSchedule,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

// const ActionsMenu = () => {
//   const menu = (
//     <Menu>
//       <Menu.Item key="1">Action 1</Menu.Item>
//       <Menu.Item key="2">Action 2</Menu.Item>
//     </Menu>
//   );

//   return (
//     <Dropdown overlay={menu}>
//       <a href="#">
//         More <span className="anticon anticon-down" />
//       </a>
//     </Dropdown>
//   );
// };

const SheduleInterview = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [jobs, setJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [candidateCount, setCandidateCount] = useState(0);

  useEffect(() => {
    getJobs();
  }, []);
  const token = localStorage.getItem('Token');

  const getJobs = async () => {
    if (token) {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const active = data?.jobs?.filter((job) => job.status === 'Active');

        // console.log(active);
        setJobs(active);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate('/login');
    }
  };

  const ExpandedTable = ({ data }) => {
    const columns = [
      { title: 'Name', dataIndex: 'firstName', key: 'firstName' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      // {
      //   title: 'Points /10',
      //   key: 'Video Score',
      //   render: () => (
      //     <span>
      //       <Badge status="success" />
      //       Finished
      //     </span>
      //   ),
      // },
      {
        /*title: 'Points/10', dataIndex: 'points', key: 'points'*/
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, record) => (
          <Flex justifyContent="space-evenly" align="center">
            <a href={record?.pdf} target="_blank">
              <Button
                color={textColor}
                style={{ backgroundColor: 'blue', color: 'white' }}
                fontSize="sm"
                fontWeight="700"
              >
                View CV
              </Button>
            </a>

            {/*  <Button
              color={textColor}
              style={{ backgroundColor: 'green', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
            >
              Schedule
            </Button> */}
            <Button
              color={textColor}
              style={{ backgroundColor: 'red', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
              onClick={() =>
                handleAcceptance(record.job, record._id, 'Accept', record.email)
              }
              _loading={loader}
            >
              Resend
            </Button>

            {/* <ActionsMenu /> */}
          </Flex>
        ),
      },
    ];
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const NestedTable = ({ tab }) => {
    const [jobCandidates, setJobCandidates] = useState([]);

    useEffect(() => {
      fetchCandidatesForActiveJobs();
    }, [tab, jobs]);

    const fetchCandidatesForActiveJobs = async () => {
      // const token = localStorage.getItem('Token');
      const updatedJobCandidates = [];
      if (token) {
        for (const job of jobs) {
          try {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/candidate/send/${job._id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            // const filteredCandidates= data.candidates.filter(()=>{
            //   return data.candidates.status === "new"
            // });
            // console.log(filteredCandidates);

            updatedJobCandidates.push({
              jobId: job._id,
              jobTitle: job.title,
              candidates: data.candidates || [], // assuming the API returns a 'candidates' array
            });
            console.log(updatedJobCandidates);
            const totalCandidates = updatedJobCandidates?.reduce(
              (total, job) => total + job.candidates.length,
              0,
            );
            console.log(`Total number of candidates: ${totalCandidates}`);
            setCandidateCount(totalCandidates);
          } catch (error) {
            console.error(
              `Failed to fetch candidates for job ${job.jobId}:`,
              error,
            );
          }
        }
        setJobCandidates(updatedJobCandidates);
      } else {
        navigate('/login');
      }
    };
    const mainColumns = [
      {
        title: 'SI.NO',
        dataIndex: 'sino',
        key: 'sino',
        render: (_, __, index) => index + 1,
      },
      { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
      // { title: 'Version', dataIndex: 'version', key: 'version' },
      // { title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
      // { title: 'Creator', dataIndex: 'creator', key: 'creator' },
      // { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
      // {
      //   title: 'Action',
      //   key: 'operation',
      //   render: () => <a href="#">Publish</a>,
      // },
    ];

    // Sample data filtered by tab selection
    // const dataByTab = {
    //   active: [
    //     {
    //       key: 1,
    //       name: 'Screem',
    //       platform: 'iOS',
    //       version: '10.3.4.5654',
    //       upgradeNum: 500,
    //       creator: 'Jack',
    //       createdAt: '2014-12-24 23:12:00',
    //     },
    //   ],
    //   hold: [
    //     {
    //       key: 2,
    //       name: 'TestApp',
    //       platform: 'Android',
    //       version: '5.2.1',
    //       upgradeNum: 300,
    //       creator: 'Tom',
    //       createdAt: '2014-11-20 18:45:00',
    //     },
    //   ],
    //   accept: [
    //     {
    //       key: 3,
    //       name: 'LiveApp',
    //       platform: 'Web',
    //       version: '2.0.1',
    //       upgradeNum: 150,
    //       creator: 'Lucy',
    //       createdAt: '2014-10-15 10:22:00',
    //     },
    //   ],
    //   reject: [],
    // };

    // const mainData = dataByTab[tab] || [];
    const dataSource = jobCandidates.map((job, idx) => ({
      key: job.jobId,
      sino: idx + 1,
      jobTitle: job.jobTitle,
      candidates: job.candidates,
    }));
    return (
      <Table
        className="components-table-demo-nested"
        columns={mainColumns}
        expandedRowRender={(record) => (
          <ExpandedTable data={record.candidates} />
        )}
        dataSource={dataSource}
      />
    );
  };
  const handleAcceptance = async (jobId, candidateId, status, email) => {
    // setLoader(true);
    try {
      console.log({ jobId, candidateId, email });
      const id = jwtDecode(token);

      const body = {
        email: email,
        candidateId: candidateId,
        status: status,
        userId:id.id

      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_ACCEPTANCE}/${jobId}`,
        body,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data);
      getJobs();
      // setLoader(false);
    } catch (error) {
      console.log(error);
      // setLoader(false);
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Accepted Invitations</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Congratulations on accepting the invitation! Get prepared for a
                productive interview experience ahead.
              </span>
            </Card.Header>
            <div className="report-box" style={{}}>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdSchedule}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Scheduled"
                  // growth="+23%"
                  value={candidateCount}
                />
                {/*          <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdSend}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Resend"
                  // growth="+23%"
                  value="2"
                />*/}
                {/* <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdAttachMoney}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  growth="+23%"
                  name="Reject"
                  value="3"
                /> */}
              </SimpleGrid>
            </div>
            {/*     <Card.Header>
              <Nav
                variant="tabs"
                className="candidate-tabs"
                activeKey={activeTab}
                onSelect={(selectedTab) => setActiveTab(selectedTab)}
              >
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="active" style={{ color: 'green' }}>
                    Schedule
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
                    Resend
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="accept" style={{ color: 'blue' }}>
                    Accept
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
                    Reject
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <br />
              <Card.Title as="h5">
                <code style={{ color: 'red' }}>
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
                  Invitations
                </code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>
            </Card.Header>*/}
            <Card.Body>
              <NestedTable tab={activeTab} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SheduleInterview;

import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table, Badge, Dropdown, Menu, Tabs } from 'antd';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Button,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineDashboard,
  MdPeopleAlt,
  MdCancel,
  MdOutlinePauseCircle,
  MdOutlinePersonOff,
  MdHourglassEmpty,
  MdCheckCircle,
  MdPerson,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// const ActionsMenu = () => {
//   const menu = (
//     <Menu>
//       <Menu.Item key="1">Action 1</Menu.Item>
//       <Menu.Item key="2">Action 2</Menu.Item>
//     </Menu>
//   );

//   return (
//     <Dropdown overlay={menu}>
//       <a href="#">
//         More <span className="anticon anticon-down" />
//       </a>
//     </Dropdown>
//   );
// };

const InterviewScore = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const [jobs, setJobs] = useState([]);
  const [jobCandidates, setJobCandidates] = useState([]);

  const [statusCounts, setStatusCounts] = useState({
    Shortlisted: 0,
    HRInterview: 0,
    Pending: 0,
    Hold: 0,
    Reject: 0,
  });
  useEffect(() => {
    getJobs();
  }, []);
  const token = localStorage.getItem('Token');
  //getting all jobs
  const getJobs = async () => {
    try {
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const active = data?.jobs?.filter((job) => job.status === 'Active');

        // console.log(active);
        setJobs(active);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
    }
  };
  // api to open interviewvideo
  const openVideo = async (candidateId) => {
    try {
      console.log(candidateId);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ONE_VIDEO}/${candidateId}`,
        config,
      );
      window.open(data.url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };
  const sendingHRLink = async (candidateId, jobID) => {
    try {
      const body = {
        link: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_LINK}/${candidateId}`,
        candidateId: candidateId,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_HR_LINK}/${jobID}`,
        body,
        {
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        },
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  const sendingOnboardingLink = async (candidateId, jobID) => {
    try {
      const body = {
        link: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_LINK}/${candidateId}`,
        candidateId: candidateId,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_HR_LINK}/${jobID}`,
        body,
        {
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        },
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAcceptance = async (jobId, candidateId, status, email) => {
    try {
      console.log({ jobId, candidateId, status, email });
      const body = {
        email: email,
        candidateId: candidateId,
        status: status,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_ACCEPTANCE}/${jobId}`,
        body,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data);
      getJobs();
    } catch (error) {
      console.log(error);
    }
  };
  const ExpandedTable = ({ data }) => {
    const columns = [
      { title: 'Name', dataIndex: 'firstName', key: 'firstName' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Points/10', dataIndex: 'points', key: 'points' },
      { title: 'Video Score', dataIndex: 'videoScore', key: 'videoScore' },
      // { title: 'Points/10', dataIndex: 'points', key: 'points' },

      {
        title: 'Status',
        key: 'state',
        render: () => (
          <span style={{ fontSize: 'xs' }}>
            {/* <Badge status="success" /> */}
            Finished
          </span>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: () => (
          <Flex justifyContent="space-evenly" align="center">
            <Button
              color={textColor}
              style={{ backgroundColor: 'Red', color: 'white' }}
              fontSize="xs"
              fontWeight="400"
            >
              Reject
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'Yellow', color: 'black' }}
              fontSize="xs"
              fontWeight="400"
            >
              Hold
            </Button>
            {/* <Button
              color={textColor}
              style={{ backgroundColor: 'red', color:"white" }}
              fontSize="sm"
              fontWeight="700"
            >
              Resend
            </Button> */}

            {/* <ActionsMenu /> */}
          </Flex>
        ),
      },
      {
        title: '',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, record) => (
          <Flex justifyContent="space-evenly" align="center">
            <Button
              color={textColor}
              style={{ backgroundColor: 'orange', color: 'white' }}
              fontSize="sm"
              fontWeight="400"
              onClick={() =>
                navigate(`/interview-score/transcript/${record._id}`)
              }
            >
              Transcript
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'red', color: 'white' }}
              fontSize="xs"
              fontWeight="400"
              onClick={() => openVideo(record._id)}
            >
              Recordings
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'green', color: 'white' }}
              fontSize="xs"
              fontWeight="400"
              onClick={() => sendingHRLink(record._id, record.job)}
            >
              HR Interview
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'blue', color: 'white' }}
              fontSize="xs"
              fontWeight="400"
              onClick={() => sendingOnboardingLink(record._id, record.job)}
            >
              Onboarding
            </Button>
            {/* <Button
              color={textColor}
              style={{ backgroundColor: 'red', color:"white" }}
              fontSize="sm"
              fontWeight="700"
            >
              Resend
            </Button> */}

            {/* <ActionsMenu /> */}
          </Flex>
        ),
      },
    ];
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  const fetchCandidatesForActiveJobs = useCallback(async () => {
    if (!jobs.length) return;

    // setLoader(true);

    try {
      const requests = jobs.map((job) =>
        axios.get(
          `${process.env.REACT_APP_BASE_URL}/candidate/completed/${job._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      );

      const results = await Promise.all(requests);

      const updatedJobCandidates = results.map((res, idx) => {
        const jobCandidates = res.data.candidates || [];

        const categorizedCandidates = jobCandidates.reduce(
          (acc, candidate) => {
            const status = candidate.interviewStatus || 'Pending';
            acc[status] = acc[status] || [];
            acc[status].push({
              ...candidate,
              jobId: jobs[idx]._id,
              jobTitle: jobs[idx].title,
            });
            return acc;
          },
          {
            Pending: [],
            Shortlisted: [],
            Hold: [],
            Reject: [],
            HRInterview: [],
          },
        );

        return {
          jobId: jobs[idx]._id,
          jobTitle: jobs[idx].title,
          candidates: categorizedCandidates,
        };
      });

      setJobCandidates(updatedJobCandidates);

      const counts = updatedJobCandidates.reduce((acc, job) => {
        Object.keys(job.candidates).forEach((status) => {
          acc[status] = (acc[status] || 0) + job.candidates[status].length;
        });
        return acc;
      }, {});
      console.log(counts);

      setStatusCounts(counts);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      // setLoader(false);
    }
  }, [jobs, token]);

  useEffect(() => {
    fetchCandidatesForActiveJobs();
  }, [fetchCandidatesForActiveJobs]);
  const NestedTable = ({ tab, jobCandidate }) => {
    // useEffect(() => {
    //   fetchCandidatesForActiveJobs();
    // }, [tab, jobs]);

    // const fetchCandidatesForActiveJobs = async () => {
    //   // const token = localStorage.getItem('Token');
    //   const updatedJobCandidates = [];
    //   if (token) {
    //     for (const job of jobs) {
    //       try {
    //         const { data } = await axios.get(
    //           `${process.env.REACT_APP_BASE_URL}/candidate/completed/${job._id}`,
    //           {
    //             headers: {
    //               Authorization: `Bearer ${token}`,
    //             },
    //           },
    //         );
    //         // const filteredCandidates= data.candidates.filter(()=>{
    //         //   return data.candidates.status === "new"
    //         // });
    //         // console.log(filteredCandidates);

    //         updatedJobCandidates.push({
    //           jobId: job._id,
    //           jobTitle: job.title,
    //           candidates: data.candidates || [], // assuming the API returns a 'candidates' array
    //         });
    //         console.log(updatedJobCandidates);
    //       } catch (error) {
    //         console.error(
    //           `Failed to fetch candidates for job ${job.jobId}:`,
    //           error,
    //         );
    //       }
    //     }
    //     setJobCandidates(updatedJobCandidates);
    //   } else {
    //     navigate('/login');
    //   }
    // };
    const mainColumns = [
      {
        title: 'SI.NO',
        dataIndex: 'sino',
        key: 'sino',
        render: (_, __, index) => index + 1,
      },
      { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
      // { title: 'Version', dataIndex: 'version', key: 'version' },
      // { title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
      // { title: 'Creator', dataIndex: 'creator', key: 'creator' },
      // { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
      // {
      //   title: 'Action',
      //   key: 'operation',
      //   render: () => <a href="#">Publish</a>,
      // },
    ];

    // Sample data filtered by tab selection
    const dataByTab = {
      active: [
        {
          key: 1,
          name: 'Screem',
          platform: 'iOS',
          version: '10.3.4.5654',
          upgradeNum: 500,
          creator: 'Jack',
          createdAt: '2014-12-24 23:12:00',
        },
      ],
      hold: [
        {
          key: 2,
          name: 'TestApp',
          platform: 'Android',
          version: '5.2.1',
          upgradeNum: 300,
          creator: 'Tom',
          createdAt: '2014-11-20 18:45:00',
        },
      ],
      accept: [
        {
          key: 3,
          name: 'LiveApp',
          platform: 'Web',
          version: '2.0.1',
          upgradeNum: 150,
          creator: 'Lucy',
          createdAt: '2014-10-15 10:22:00',
        },
      ],
      reject: [],
    };

    const mainData = dataByTab[tab] || [];
    const dataSource = jobCandidate.map((job, idx) => ({
      key: job.jobId,
      sino: idx + 1,
      jobTitle: job.jobTitle,
      candidates: job.candidates[tab],
    }));
    console.log(dataSource);

    return (
      <Table
        className="components-table-demo-nested"
        columns={mainColumns}
        expandedRowRender={(record) => (
          <ExpandedTable data={record.candidates} />
        )}
        dataSource={dataSource}
      />
    );
  };
  const renderJobsTable = (activeTab) => {
    return (
      <div>
        <Card.Title as="h5">
          <code style={{ color: 'red' }}>
            {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Invitations
          </code>
        </Card.Title>
        <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>

        <Card.Body>
          <NestedTable tab={activeTab} jobCandidate={jobCandidates} />
        </Card.Body>
      </div>
    );
  };
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Interview Results</code>
              </Card.Title>
              <br />
              <span className="d-block mt-2 text-muted">
                Welcome to AI-Enhanced Interview Practice, your gateway to a
                smarter, more effective approach to interview preparation
                tailored to your industry.
              </span>
            </Card.Header>

            <div className="report-box">
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdHourglassEmpty}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Pending"
                  // growth="+23%"
                  value={statusCounts.Pending}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdCheckCircle}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Shortlisted"
                  // growth="+23%"
                  value={statusCounts.Shortlisted}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdPerson}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="HR Interview"
                  // growth="+23%"
                  value={statusCounts.HRInterview}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePauseCircle}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Hold"
                  value={statusCounts.Hold}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdCancel}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Rejected"
                  value={statusCounts.Reject}
                />
              </SimpleGrid>
            </div>
            <Card.Header>
              {' '}
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Pending" key="1">
                  {renderJobsTable('Pending')}
                </Tabs.TabPane>
              
                <Tabs.TabPane tab="Shortlisted" key="2">
                  {renderJobsTable('Shortlisted')}
                </Tabs.TabPane>
                <Tabs.TabPane tab="HRInterview" key="3">
                {renderJobsTable('HRInterview')}
              </Tabs.TabPane>
                <Tabs.TabPane tab="Hold" key="4">
                  {renderJobsTable('Hold')}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Reject" key="5">
                  {renderJobsTable('Reject')}
                </Tabs.TabPane>
              </Tabs>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InterviewScore;

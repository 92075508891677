import React, { useRef, useState } from 'react';
import usePost from '../../Utilities/hooks/usePost';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Modal } from 'antd';
const JobShareMail = ({ jobId, setIsJobModalVisible, isJobModalVisible }) => {
  const [email, setEmail] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRef = useRef('');
  const setInputValue = (name, text) => {
    name.current.value = text;
  };
  const token = localStorage.getItem('Token');

  const sendEmail = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/job/${jobId}`;

      const payload = {
        email: email,
        emailContent: emailContent,
        jobUrl: url,
      };
      console.log(payload);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_INVITE} + ${jobId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data);
      toast.success('Emails sent', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      toast.error('Unable to send email please try again later', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      });
    }
  };
  const resetDataToDefault = () => {
    emailContent = '';
    email = '';
    setIsJobModalVisible(false);
  };
  const handleCancel = () => {
    setIsJobModalVisible(false);
  };
  return (
    <Modal
      centered
      open={isJobModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="modal-content">
        <div>
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Interview Invitation
          </h1>
        </div>
        <div className="modal-body bg-white">
          <div className=" d-flex flex-column mb-3 align-items-center justify-content-center border-bottom pb-3">
            <form className="w-100 d-flex flex-column align-items-start">
              <label className="me-3 mb-1">Send invites to:</label>
              <input
                type="email"
                class="form-control w-100 mb-2"
                //   ref={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <label className="me-3 mb-1">Email Content:</label>
              <textarea
                rows={4}
                class="form-control w-100 shadow-none outline-none mb-2"
                //   ref={emailContent}
                onChange={(event) => {
                  setEmailContent(event.target.value);
                }}
              />
            </form>
            <div className="w-100 d-flex flex-row align-items-center justify-content-end my-3">
              <button
                onClick={() => {
                  sendEmail();
                }}
                className="btn-success btn w-25"
                disabled={loading}
              >
                {loading ? 'Sending....' : 'Send'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default JobShareMail;

import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table, Badge, Dropdown, Menu ,Tabs} from 'antd';
import {
  Avatar,
  Box,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,

} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlinePauseCircle,
  MdOutlineDashboard,
  MdCancel,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Jobs from './Jobs';
import axios from 'axios';
import { Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
const Candidates = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [jobs, setJobs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobCandidates, setJobCandidates] = useState([]);
  const [categorizedCandidates, setCategorizedCandidates] = useState({
    Accept: [],
    Reject: [],
    Hold: [],
    Pending: [],
  });

  const [statusCounts, setStatusCounts] = useState({
    Accept: 0,
    Reject: 0,
    Hold: 0,
  });
  useEffect(() => {
    getJobs();
  }, []);
  const token = localStorage.getItem('Token');

  const getJobs = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const active = data?.jobs?.filter((job) => job.status === 'Active');

      // console.log(active);
      setJobs(active);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleAcceptance = async (jobId, candidateId, status, email) => {
    try {
      console.log({ jobId, candidateId, status, email });
       const id = jwtDecode(token);
      const body = {
        email: email,
        candidateId: candidateId,
        status: status,
        userId:id.id
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_ACCEPTANCE}/${jobId}`,
        body,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data);
      getJobs();
    } catch (error) {
      console.log(error);
    }
  };
  const ActionsMenu = () => {
    const menu = (
      <Menu>
        <Menu.Item key="1">Action 1</Menu.Item>
        <Menu.Item key="2">Action 2</Menu.Item>
      </Menu>
    );

    // return (
    //   <Dropdown overlay={menu}>
    //     <a href="#">
    //       More <span className="anticon anticon-down" />
    //     </a>
    //   </Dropdown>
    // );
  };

  const ExpandedTable = ({ data }) => {
    const columns = [
      // { title: 'Date', dataIndex: 'date', key: 'date' },
      { title: 'Name', dataIndex: 'firstName', key: 'firstName' },
      { title: 'E-Mail', dataIndex: 'email', key: 'email' },
      {
        title: 'Location',
        dataIndex: 'currentLocation',
        key: 'currentLocation',
      },
      { title: 'CV Score', dataIndex: 'cvScore', key: 'cvScore' },
      // { title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },

      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, record) => (
          <Flex justifyContent="space-evenly" align="center">
            <a href={record.pdf} target="_blank">
              <Button
                color={textColor}
                style={{ backgroundColor: 'blue', color: 'white' }}
                fontSize="sm"
                fontWeight="700"
              >
                View CV
              </Button>{' '}
            </a>

            <Button
              color={textColor}
              style={{ backgroundColor: 'green', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
              onClick={() =>
                handleAcceptance(record.job, record._id, 'Accept', record.email)
              }
            >
              Accept
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'red', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
              onClick={() =>
                handleAcceptance(record.job, record._id, 'Reject', record.email)
              }
            >
              Reject
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'orange', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
              onClick={() =>
                handleAcceptance(record.job, record._id, 'Hold', record.email)
              }
            >
              Hold
            </Button>
          </Flex>
        ),
      },
    ];

    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
      />
    );
  };
  const fetchCandidatesForActiveJobs = useCallback(async () => {
    if (!jobs.length) return;

    setLoader(true);

    try {
      const requests = jobs.map((job) =>
        axios.get(
          `${process.env.REACT_APP_BASE_URL}/candidate/new/${job._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      );

      const results = await Promise.all(requests);

      const updatedJobCandidates = results.map((res, idx) => {
        const jobCandidates = res.data.candidates || [];

        const categorizedCandidates = jobCandidates.reduce(
          (acc, candidate) => {
            const status = candidate.candidateStatus || 'Pending';
            acc[status] = acc[status] || [];
            acc[status].push({
              ...candidate,
              jobId: jobs[idx]._id,
              jobTitle: jobs[idx].title,
            });
            return acc;
          },
          { Accept: [], Reject: [], Hold: [], Pending: [] },
        );

        return {
          jobId: jobs[idx]._id,
          jobTitle: jobs[idx].title,
          candidates: categorizedCandidates,
        };
      });

      setJobCandidates(updatedJobCandidates);

      // const counts = updatedJobCandidates.reduce((acc, job) => {
      //   job.candidates.forEach((candidate) => {
      //     acc[candidate.candidateStatus] =
      //       (acc[candidate.candidateStatus] || 0) + 1;
      //   });
      //   return acc;
      // }, {});
      // const categorized = updatedJobCandidates.reduce(
      //   (acc, job) => {
      //     job.candidates.forEach((candidate) => {
      //       if (candidate.candidateStatus === 'Pending') {
      //         acc.Pending.push({
      //           ...candidate,
      //           jobTitle: job.jobTitle,
      //         });
      //       } else if (candidate.candidateStatus === 'Accept') {
      //         acc.Accept.push({
      //           ...candidate,
      //           jobTitle: job.jobTitle,
      //         });
      //       } else if (candidate.candidateStatus === 'Reject') {
      //         acc.Reject.push({
      //           ...candidate,
      //           jobTitle: job.jobTitle,
      //         });
      //       } else if (candidate.candidateStatus === 'Hold') {
      //         acc.Hold.push({
      //           ...candidate,
      //           jobTitle: job.jobTitle,
      //         });
      //       }
      //     });
      //     return acc;
      //   },
      //   { Accept: [], Reject: [], Hold: [], Pending: [] },
      // );

      // setCategorizedCandidates(categorized);

      // console.log(categorized);
      const counts = updatedJobCandidates.reduce((acc, job) => {
        Object.keys(job.candidates).forEach((status) => {
          acc[status] = (acc[status] || 0) + job.candidates[status].length;
        });
        return acc;
      }, {});
      console.log(counts);

      setStatusCounts(counts);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setLoader(false);
    }
  }, [jobs, token]);

  useEffect(() => {
    fetchCandidatesForActiveJobs();
  }, [fetchCandidatesForActiveJobs]);
  const NestedTable = ({ tab, jobCandidate }) => {
    const filteredData = jobs.map((job, idx) => ({
      key: job._id,
      jobTitle: job.title,
      sino: idx + 1,
      // candidates: filterCandidatesByTab(job),
    }));
    const mainColumns = [
      {
        title: 'SI.NO',
        dataIndex: 'sino',
        key: 'sino',
        render: (_, __, index) => index + 1,
      },
      { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
      // { title: 'Version', dataIndex: 'version', key: 'version' },
      // { title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
      // { title: 'Creator', dataIndex: 'creator', key: 'creator' },
      // { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
      // {
      //   title: 'Action',
      //   key: 'operation',
      //   render: () => <a href="#">Publish</a>,
      // },
    ];

    const dataSource = jobCandidate.map((job, idx) => ({
      key: job.jobId,
      sino: idx + 1,
      jobTitle: job.jobTitle,
      candidates: job.candidates[tab],
    }));

    return (
      <Table
        className="components-table-demo-nested"
        columns={mainColumns}
        expandedRowRender={(record) => (
          <ExpandedTable data={record.candidates} />
        )}
        dataSource={dataSource}
        loading={loader}
      />
    );
  };
  const renderJobsTable = (activeTab) => {
    return (
      <div>
        
          <Card.Title as="h5">
            <code style={{ color: 'red' }}>
              {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
              Invitations
            </code>
          </Card.Title>
          <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>
       
        <Card.Body>
          <NestedTable tab={activeTab} jobCandidate={jobCandidates} />
        </Card.Body>
      </div>
    );
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Search & Invite Candidates</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Explore our candidate database effortlessly. Narrow down your
                search using filters that matter to you - skills, experience,
                and more. When you find the perfect match, send an interview
                invitation and kickstart the hiring process!
              </span>
            </Card.Header>
            <div className="report-box" style={{}}>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineDashboard}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Pending"
                  value={statusCounts.Pending}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePauseCircle}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Hold"
                  value={statusCounts.Hold}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdCancel}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Reject"
                  value={statusCounts.Reject}
                />
              </SimpleGrid>
            </div>
            <Card.Header>  <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Pending" key="1">
              {renderJobsTable('Pending')}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hold" key="2">
              {renderJobsTable('Hold')}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Reject" key="3">
              {renderJobsTable('Reject')}
            </Tabs.TabPane>
          </Tabs></Card.Header>
          
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Candidates;
{
  /* 
<Nav
variant="tabs"
className="candidate-tabs"
activeKey={activeTab}
onSelect={(selectedTab) => setActiveTab(selectedTab)}
>
<Nav.Item className="candidate-tab-btn">
  <Nav.Link eventKey="active" style={{ color: 'green' }}>
    Active
  </Nav.Link>
</Nav.Item> 
<Nav.Item className="candidate-tab-btn">
  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
    Hold
  </Nav.Link>
</Nav.Item>
<Nav.Item className="candidate-tab-btn">
  <Nav.Link eventKey="accept" style={{ color: 'blue' }}>
    Accept
  </Nav.Link>
</Nav.Item>
<Nav.Item className="candidate-tab-btn">
  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
    Reject
  </Nav.Link>
</Nav.Item>
</Nav>



 // const filterCandidatesByTab = (job) => {
    //   return job.candidates.filter(
    //     (candidate) => candidate.status === tab
    //   );
    // };

    // useEffect(() => {
    //   console.log("calling");

    //   fetchCandidatesForActiveJobs();
    // }, []);

    // const fetchCandidatesForActiveJobs = async () => {
    //   // const token = localStorage.getItem('Token');
    //   setLoader(true);
    //   const updatedJobCandidates = [];
    //   if (token) {
    //     for (const job of jobs) {
    //       try {
    //         const { data } = await axios.get(
    //           `${process.env.REACT_APP_BASE_URL}/candidate/new/${job._id}`,
    //           {
    //             headers: {
    //               Authorization: `Bearer ${token}`,
    //             },
    //           },
    //         );
    //         // const filteredCandidates= data.candidates.filter(()=>{
    //         //   return data.candidates.status === "new"
    //         // });
    //         // console.log(filteredCandidates);

    //         updatedJobCandidates.push({
    //           jobId: job._id,
    //           jobTitle: job.title,
    //           candidates: data.candidates || [], // assuming the API returns a 'candidates' array
    //         });
    //         console.log(updatedJobCandidates);
    //         // if (updatedJobCandidates?.candidates.length != 0) {
    //           const counts = updatedJobCandidates.reduce((acc, job) => {
    //             job.candidates.forEach(candidate => {
    //               acc[candidate.candidateStatus] = (acc[candidate.candidateStatus] || 0) + 1;
    //             });
    //             return acc;
    //           }, {});

    //           console.log('counts', counts);

    //           setStatusCounts({
    //             Accept: counts.Accept || 0,
    //             Reject: counts.Reject || 0,
    //             Hold: counts.Hold || 0,
    //           });
    //         // }
    //       } catch (error) {
    //         console.error(
    //           `Failed to fetch candidates for job ${job.jobId}:`,
    //           error,
    //         );
    //       }
    //     }
    //     setJobCandidates(updatedJobCandidates);
    //     setLoader(false);
    //   } else {
    //     navigate('/login');
    //     setLoader(false);
    //   }
    // };



     // Sample data filtered by tab selection
    // const dataByTab = {
    //   active: [
    //     {
    //       key: 1,
    //       sino: 1,
    //       jobTitle: 'Devops Engineer',
    //     },
    //   ],
    //   hold: [
    //     {
    //       key: 2,
    //       sino: 2,
    //       jobTitle: 'Devops Engineer',
    //     },
    //   ],
    //   accept: [
    //     {
    //       key: 3,
    //       sino: 3,
    //       jobTitle: 'Devops Engineer',
    //     },
    //   ],
    //   reject: [],
    // };

    // const mainData = dataByTab[tab] || [];
    // console.log(filteredData);*/
}

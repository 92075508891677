import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const ColumnChart = ({ chartData, chartOptions }) => {
  const [chartState, setChartState] = useState({
    chartData: [],
    chartOptions: {},
  });

  useEffect(() => {
    console.log(chartData);

    setChartState({
      chartData: chartData || [],
      chartOptions: chartOptions || {},
    });
  }, [chartData, chartOptions]); // Update when props change

  return (
    <Chart
      options={chartState.chartOptions}
      series={chartState.chartData}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

export default ColumnChart;

// import React, { Component } from "react";
// import Chart from "react-apexcharts";

// class ColumnChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       chartData: [],
//       chartOptions: {},
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       chartData: this.props.chartData,
//       chartOptions: this.props.chartOptions,
//     });
//   }

//   render() {
//     return (
//       <Chart
//         options={this.state.chartOptions}
//         series={this.state.chartData}
//         type='bar'
//         width='100%'
//         height='100%'
//       />
//     );
//   }
// }

// export default ColumnChart;

// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import PieChart from 'components/charts/PieChart';
// import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from 'components/separator/Separator';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function InterviewedCount(props) {
  const token = localStorage.getItem('Token');
    const navigate = useNavigate();
  
    const { ...rest } = props;
  
    const [candidatesCount, setcandidatesCount] = useState([]);
    const [jobsTitle, setJobsTitle] = useState([]);
    const [allCandidatesByJob, setAllCandidatesByJob] = useState([]);
    // console.log(jobsTitle);
    useEffect(() => {
      getJobsWithCandidateCounts();
      console.log(allCandidatesByJob);
      console.log(jobsTitle);
      console.log(candidatesCount);
    }, []);
    const getJobsWithCandidateCounts = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
  
        const allCandidatesByJob = await Promise.all(
          data?.jobs.map(async (job) => {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/candidate/send/${job._id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
  
            return {
              _id: job._id,
              title: job.title,
              candidatesCount: data?.candidates ? data?.candidates?.length : 0,
            };
          }),
        );
  
        console.log(allCandidatesByJob);
        setAllCandidatesByJob(allCandidatesByJob);
        const candidatesCount = allCandidatesByJob.map(
          (job) => job.candidatesCount,
        );
        const jobsTitle = allCandidatesByJob.map((job) => job.title);
        setJobsTitle(jobsTitle);
        setcandidatesCount(candidatesCount);
        // return allCandidatesByJob;
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };
    const pieChartData = candidatesCount;
    const pieChartOptions = {
      labels: jobsTitle,
      colors: ['#00000'],
      chart: {
        width: '50px',
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: ['#4318FF', '#6AD2FF', '#EFF4FB'],
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
      },
    };
  
    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const cardColor = useColorModeValue('white', 'navy.700');
    const cardShadow = useColorModeValue(
      '0px 18px 40px rgba(112, 144, 176, 0.12)',
      'unset',
    );
    const allValuesZero = candidatesCount.every((value) => value === 0);
  
    return (
      <Card p="20px" align="center" direction="column" w="100%" {...rest}>
        <Flex
          px={{ base: '0px', '2xl': '10px' }}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mb="8px"
        >
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            Interview Scheduled Candidates
          </Text>
          {/*<Select
            fontSize='sm'
            variant='subtle'
            defaultValue='monthly'
            width='unset'
            fontWeight='700'>
            <option value='daily'>Daily</option>
            <option value='monthly'>Monthly</option>
            <option value='yearly'>Yearly</option>
          </Select>*/}
        </Flex>
     {allValuesZero ? (
          <Text color={textColor} fontSize="lg" fontWeight="700" py="70px">
            No candidates
          </Text>
        ) : (
        <PieChart
          h="100%"
          w="100%"
          chartData={pieChartData}
          chartOptions={pieChartOptions}
        />)}
        <Card
          bg={cardColor}
          flexDirection="row"
          boxShadow={cardShadow}
          w="100%"
          p="15px"
          px="20px"
          mt="15px"
          mx="auto"
        >
          {allCandidatesByJob?.map((e, idx) => (
            <>
              <Flex direction="column" py="5px" key={idx}>
                <Flex align="center">
                  <Box
                    h="8px"
                    w="8px"
                    bg="brand.500"
                    borderRadius="50%"
                    me="4px"
                  />
                  <Text
                    fontSize="xs"
                    color="secondaryGray.600"
                    fontWeight="700"
                    mb="5px"
                  >
                    {e?.title}
                  </Text>
                </Flex>
                <Text fontSize="lg" color={textColor} fontWeight="700">
                  {e?.candidatesCount}
                </Text>
              </Flex>
              <VSeparator mx={{ base: '60px', xl: '60px', '2xl': '60px' }} />
            </>
          ))}
        </Card>
      </Card>
    );
}

import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";


const PieChart = ({ chartData, chartOptions }) => {
  const [chartState, setChartState] = useState({
    chartData: [],
    chartOptions: {},
  });

  useEffect(() => {
    // console.log(chartData);

    setChartState({
      chartData: chartData || [],
      chartOptions: chartOptions || {},
    });
  }, [chartData, chartOptions]); // Update when props change

  return (
    <ReactApexChart
    options={chartState.chartOptions}
      series={chartState.chartData}
    type='pie'
    width='100%'
    height='55%'
  /> 
  );
};

export default PieChart;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// class PieChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       chartData: [],
//       chartOptions: {},
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       chartData: this.props.chartData,
//       chartOptions: this.props.chartOptions,
//     });
//   }

//   render() {
//     return (
//       <ReactApexChart
//         options={this.state.chartOptions}
//         series={this.state.chartData}
//         type='pie'
//         width='100%'
//         height='55%'
//       />
//     );
//   }
// }

// export default PieChart;

{/**<ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type='pie'
        width='100%'
        height='55%'
      /> */}
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useState, useEffect } from 'react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import InterviewedCount from './components/InterviewedPiechart';
import HiredCount from './components/HiredPiechart';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function UserReports() {
  const navigate = useNavigate();
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [jobCount, setJobCount] = useState();
  const [candidateCount, setCandidateCount] = useState();
  const [hiredCandidatesCount, setHiredCandidatesCount] = useState();
  const [interviewedCount, setInterviewedCount] = useState();
  useEffect(() => {
    getobCounts();
  }, []);
  const getobCounts = async () => {
    try {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const api1 = axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_JOB_COUNT}`,
        { headers },
      );
      const api2 = axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CANDIDATE_COUNT}`,
        { headers },
      );
      const api3 = axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_INTERVIEWED_CANDIDATE_COUNT}`,
        { headers },
      );
      const api4 = axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HAIRED_CANDIDATE_COUNT}`,
        { headers },
      );

      // Wait for all APIs to resolve
      const [response1, response2, response3, response4] = await Promise.all([
        api1,
        api2,
        api3,
        api4,
      ]);
      console.log(response1);

      // Update state with the fetched data
      console.log({
        api1: response1.data,
        api2: response2.data,
        api3: response3.data,
        api4: response4.data,
      });
      setJobCount(response1.data);
      setCandidateCount(response2.data);
      setInterviewedCount(response3.data);
      setHiredCandidatesCount(response4.data);
    } catch (error) {
      console.log(error);
    }
  };

  let token = localStorage.getItem('Token');
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, []);

  const jobs = [
    { 
      _id: '676019a499100d756102339e',
      title: 'Cloud Engineer',
      createdAt: '2024-12-16T12:14:28.088Z',
    },
    {
      _id: '67609a5056ece90e493e51e9',
      title: 'Java Developer',
      createdAt: '2024-12-16T21:23:28.951Z',
    },
    // Add more job objects as needed
  ];
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <DailyTraffic jobs={jobs} />
        <PieCard
          candidateCount={candidateCount}
          hiredCandidatesCount={hiredCandidatesCount}
          interviewedCount={interviewedCount}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <InterviewedCount count={interviewedCount} />
        <HiredCount count={hiredCandidatesCount} />
      </SimpleGrid>
    </Box>
  );
}
{
  /*  <WeeklyRevenue />
        <TotalSpent />*/
}
{
  /* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */
}
{
  /* <DailyTraffic /> */
}
{
  /* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
        
        </SimpleGrid>
      </SimpleGrid> */
}

{
  /* <SimpleGrid
columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
gap='20px'
mb='20px'>
<MiniStatistics
  startContent={
    <IconBox
      w='56px'
      h='56px'
      bg={boxBg}
      icon={
        <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
      }
    />
  }
  name='Earnings'
  value='$350.4'
/>
<MiniStatistics
  startContent={
    <IconBox
      w='56px'
      h='56px'
      bg={boxBg}
      icon={
        <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
      }
    />
  }
  name='Spend this month'
  value='$642.39'
/>
<MiniStatistics growth='+23%' name='Sales' value='$574.34' />
<MiniStatistics
  endContent={
    <Flex me='-16px' mt='10px'>
      <FormLabel htmlFor='balance'>
        <Avatar src={Usa} />
      </FormLabel>
      <Select
        id='balance'
        variant='mini'
        mt='5px'
        me='0px'
        defaultValue='usd'>
        <option value='usd'>USD</option>
        <option value='eur'>EUR</option>
        <option value='gba'>GBA</option>
      </Select>
    </Flex>
  }
  name='Your balance'
  value='$1,000'
/>
<MiniStatistics
  startContent={
    <IconBox
      w='56px'
      h='56px'
      bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
      icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
    />
  }
  name='New Tasks'
  value='154'
/>
<MiniStatistics
  startContent={
    <IconBox
      w='56px'
      h='56px'
      bg={boxBg}
      icon={
        <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
      }
    />
  }
  name='Total Projects'
  value='2935'
/>
</SimpleGrid> */
}

{
  /*<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
        <Tasks />
        <MiniCalendar h="100%" minW="100%" selectRange={false} />
      </SimpleGrid>*/
}
